import styled from 'styled-components'

const WidgetPage = styled.div`
  height: 100vh;
  overflow: hidden;
`

const Body = styled.div`
  width: 100%;
`

export { WidgetPage, Body }
