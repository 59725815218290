import React, { useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { getUrlEnd } from '../../helpers/getUrlEnd'

import * as S from './WidgetPage.style'

interface Props {
  match: any
}

const WidgetPage: React.FC<Props> = () => {
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search)
    const screenTopPadding = queryParams.get('screenTopPadding') || '35'

    const script = document.createElement('script')
    script.setAttribute('type', 'text/javascript')
    script.setAttribute('id', 'chatbot-initials-script')

    const botId = getUrlEnd(window.location)

    if (window.location.hostname === 'localhost') {
      script.setAttribute('src', 'http://localhost:3030/script-chatbot.js')
      script.setAttribute('data-server-url', 'http://localhost:8080/api')
      script.setAttribute('data-bot-id', botId)
    } else {
      script.setAttribute('src', 'https://aime-prod.botscrew.net/widget/script-chatbot.js')
      script.setAttribute('data-server-url', 'https://aime-prod.botscrew.net/api')
      script.setAttribute('data-bot-id', botId)
    }

    script.setAttribute('embedded', 'true')
    script.setAttribute('placeholder-id', 'chatbot-container')
    script.setAttribute('embedded-width', '100%25')
    script.setAttribute('embedded-height', '100%25')

    script.setAttribute('screen-top-padding', screenTopPadding)

    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [])

  return (
    <S.WidgetPage>
      <S.Body>
        <div
          id="chatbot-container"
          style={{
            width: '100%',
            height: '100%',
            position: 'absolute',
            overflow: 'hidden'
          }}
        ></div>
      </S.Body>
    </S.WidgetPage>
  )
}

export default withRouter(WidgetPage)
